export default {
    web3: {
        no_install: '没有安装钱包',
        link_error: {
            waiting: '连接等待确认中，请检查您的钱包软件以确认',
            reject: '您已取消连接',
            fail: '连接失败',
        },
        sign_error: {
            reject: '您已取消签名',
            fail: '签名失败',
        },
        logout_title: '是否断开钱包连接？',
        logouted: '您已断开钱包的连接！',
    },
    home: {
        Investing: "Investing in the future",
        Simplified: " Simplified and secure participation in crypto investing",
        Invest: "Invest Now",
        AUM: "AUM",
        Cumulative: "Cumulative",
        APY: "Yield",
        MatrixProduct: "Matrix Product",
        Start: "Start your crypto journey today and invest like never before!",
        METFFund: "METF Fund",
        Asecure: " A secure way to get diversified exposure to bitcoin and leading cryptocurrencies. The Fund seeks to track an Index comprised of the 10 most highly valued cryptocurrencies, screened and monitored for certain risks, weighted by market capitalization, and rebalanced monthly.",
        learnmore: "learn more",
        Stakenow: "Stake now",
        HowMatrix: "How Matrix works",
        Step1: "Step1",
        Step2: "Step2",
        Step3: "Step3",
        Subsribe: "Subsribe",
        Getsecure: "Get secure custody",
        Redeem: "Redeem",
        WhyMatrix: "Why Matrix?",
        WhyMatrixs: "Why Matrix",
        Threereasons: "Three reasons why we believe Matrix Fund should be represented in a portfolio. ",
        Future: "Invest In The Future",
        Webelieve: "We believe innovation is changing lives and businesses, creating opportunities to own tomorrow 's disruptors by investing in the future today.",
        Capitalize: "Capitalize On Inefficiencies",
        opportunities: "We believe the opportunities resulting from disruptive innovation often are underestimated or misunderstood by traditional investment managers.",
        MakeThe: "Make The World Better",
        transform: "We believe innovation can help transform and solve some of the world’ s most persistent problems and help change the way the world works. ",
        TopCrypto: "Top Crypto Assets",
        LetMatrix: "Let Matrix help you take the next step in your crypto journey.",
        BTC: "BTC",
        Bitcoin: "Bitcoin (BTC) is a peer-to-peer cryptocurrency that aims to function as a means of exchange that is independent of any central authority. ",
        SOL: "SOL",
        Solana: "Solana is a high-speed single-layer blockchain, currently supporting peak capacity of 65 k transactions per second and 400 ms block times using a network timestamp system called Proof - of - History('PoH'). ",
        AVAX: "AVAX",
        Avalanche: "Avalanche is an open-source platform for launching Decentralized Finance (DeFi) applications and enterprise blockchain deployments in an interoperable, highly scalable ecosystem.",
        Contactus: "Contact us",
        Jointhe: "Join the community and ask questions",
        Joinin: "Join in MatrixFund",
        moreabout: "Learn more about MatrixFund, chat with us and have your say in the future of the MatrixFund ecosystem",
        ourcommunity: "Join our community",
        ETH: "ETH",
        Ethereum: "Ethereum is a decentralized open-source blockchain system that features its own cryptocurrency,Ether. ",
        XRP: "XRP",
        Ripple: "Ripple, the company behind xRapid, xCurrent and XRP solutions continues to invest heavily in developing its ecosystem.",
        DOGE: "DOGE",
        novelty: " Dogecoin (DOGE) is a novelty cryptocurrency originally launched as a “memecoin” within the cryptocurrency community.",
        BNB: "BNB",
        Fromthe: "From the ICO, Binance issued its own coin called the Binance Coin, with BNB as the symbol. BNB runs natively on Binance chain.",
        ADA: "ADA",
        Cardano: "Cardano (ADA) is an open-source Proof-of-Stake (PoS) blockchain network,based on a wide array of design components that include a dApp development platform.",
        DOT: "DOT",
        Polkadot: "Polkadot is a sharded network for cross-chain communication and interoperability,connecting multiple blockchains into one unified network. ",
        Strategies: "Investment Strategies",
        Ascrypto: " As crypto grows, we believe everyone should have a simple and familiar way to access it — a way that’s expertly designed, clearly explained, and professionally managed. We make crypto easy.",
        Cryptoasset: "Cryptoasset",
        blockchains: "Matrix believes public blockchains are powering novel forms of coordination across finance and the internet. The Investment Manager believes that cryptocurrency value and market share dynamics will be “power law distributed,” meaning that a few cryptocurrencies will capture the majority of value.",
        Fintech: "Fintech Innovation",
        tofintech: "Aims to provide exposure to fintech innovations including mobile payments, digital wallets, peer-to-peer lending, blockchain technology, and financial risk transformation. These innovations should revolutionize the financial industry, impacting every sector of the global economy.",
        MatrixVenture: "Matrix Venture",
        providebroad: "Aims to provide broad exposure to disruptive innovation in both the private and public markets. Matrix believes innovations centered around artificial intelligence, robotics and blockchain technology will change the way the world works and deliver outsized growth as industries transform.",
        NextGeneration: "Next Generation Internet",
        Seeks: "Seeks to provide exposure to artificial intelligence, deep learning, big data, cloud computing, cybersecurity, and blockchain technology. These innovations are changing the way the world manages information, analyzes data, purchases and consumes goods, and communicates across the globe.",
        Disruptors: "Matrix Early Stage Disruptors",
        earlystage: "Aims to provide broad exposure to early-stage disruptors in the public markets. Matrix believes at the forefront of the innovation wave are “early-stage disruptors”— either newer public companies or companies that introduced new technologically-enabled products or services that are in the early stages of development.",
        Partner: "Partner",
        Movingforward: "Moving forward with the best partners in the web3 world",
        ContactUs1: "Contact Us",
        Everyinvestor: "Every investor is unique, so let’s connect one-on-one",
        byemail: "Contact us by email",
        Community: "Community",
        Product: "Product",
        Home: "Home"
    }






}